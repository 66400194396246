<template>
  <div class="section-content pb-0">
    <div class="container">
      <div class="section-heading section-heading--divider-top">
        <h2 class="section-heading__title">How to play</h2>
      </div>
    </div>
    <div class="section-media section-media--bg1 jarallax" data-jarallax='{"speed": 0.4}'>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/jt9Owcm-2aI" style="display: block; margin: 0 auto 20px;" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
</template>