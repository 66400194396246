
import { defineComponent } from 'vue';
import Testimonials from '../components/Testimonials.vue';
import HowToPlay from '../components/HowToPlay.vue';
import QuestionsAndAnswers from '../components/QuestionsAndAnswers.vue';
import PageHeader from '../components/PageHeader.vue'

export default defineComponent({
  name: 'About',
  components: {
    PageHeader,
    Testimonials,
    HowToPlay,
    QuestionsAndAnswers
  },
  mounted() {
    const w = window as any;
    w.fbq('track', 'ViewContent');
  }
});
