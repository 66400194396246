import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "site-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")
  const _component_how_to_play = _resolveComponent("how-to-play")
  const _component_testimonials = _resolveComponent("testimonials")
  const _component_questions_and_answers = _resolveComponent("questions-and-answers")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_page_header, {
      image: "rusted",
      title: "About"
    }),
    _createVNode("main", _hoisted_1, [
      _createVNode(_component_how_to_play),
      _createVNode(_component_testimonials),
      _createVNode(_component_questions_and_answers)
    ])
  ]))
}