
import { defineComponent } from 'vue';
import { getFAQs, ParsedItem } from '../lib/Contentful'

interface FAQData {
  faqs: ParsedItem[];
}

export default defineComponent({
  name: 'QuestionsAndAnswers',
  data(): FAQData {
    return {
      faqs: []
    }
  },
  async mounted() {
    await this.getFAQs()
  },
  methods: {
    async getFAQs() {
      this.faqs = await getFAQs()
    }
  }
});
